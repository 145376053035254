import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import { AddressFragmentDoc } from '../fragments/addressFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerRichInformationQueryVariables = Types.Exact<{
    customerAccessToken: Types.Scalars['String'];
}>;

export type CustomerRichInformationQuery = {
    __typename: 'QueryRoot';
    customer?:
        | {
              __typename: 'Customer';
              id: string;
              firstName?: string | undefined;
              lastName?: string | undefined;
              email?: string | undefined;
              phone?: string | undefined;
              billingAddresses?: { __typename?: 'Metafield'; value: string } | undefined;
              vatNumber?: { __typename?: 'Metafield'; value: string } | undefined;
              addresses: {
                  __typename: 'MailingAddressConnection';
                  nodes: Array<{
                      __typename: 'MailingAddress';
                      id: string;
                      address1?: string | undefined;
                      address2?: string | undefined;
                      city?: string | undefined;
                      firstName?: string | undefined;
                      lastName?: string | undefined;
                      company?: string | undefined;
                      country?: string | undefined;
                      phone?: string | undefined;
                      province?: string | undefined;
                      provinceCode?: string | undefined;
                      zip?: string | undefined;
                      countryCode?: Types.CountryCode | undefined;
                  }>;
              };
          }
        | undefined;
};

export const CustomerRichInformationDocument = gql`
    query CustomerRichInformation($customerAccessToken: String!) {
        __typename
        customer(customerAccessToken: $customerAccessToken) {
            __typename
            id
            firstName
            lastName
            email
            phone
            billingAddresses: metafield(key: "billing_address", namespace: "custom") {
                value
            }
            vatNumber: metafield(key: "vat_number", namespace: "custom") {
                value
            }
            addresses(first: 6) {
                __typename
                nodes {
                    ...Address
                }
            }
        }
    }
    ${AddressFragmentDoc}
`;

/**
 * __useCustomerRichInformationQuery__
 *
 * To run a query within a React component, call `useCustomerRichInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRichInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRichInformationQuery({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *   },
 * });
 */
export function useCustomerRichInformationQuery(
    baseOptions: Apollo.QueryHookOptions<CustomerRichInformationQuery, CustomerRichInformationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CustomerRichInformationQuery, CustomerRichInformationQueryVariables>(
        CustomerRichInformationDocument,
        options,
    );
}
export function useCustomerRichInformationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CustomerRichInformationQuery, CustomerRichInformationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CustomerRichInformationQuery, CustomerRichInformationQueryVariables>(
        CustomerRichInformationDocument,
        options,
    );
}
export type CustomerRichInformationQueryHookResult = ReturnType<typeof useCustomerRichInformationQuery>;
export type CustomerRichInformationLazyQueryHookResult = ReturnType<typeof useCustomerRichInformationLazyQuery>;
export type CustomerRichInformationQueryResult = Apollo.QueryResult<
    CustomerRichInformationQuery,
    CustomerRichInformationQueryVariables
>;
