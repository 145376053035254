import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
export type AddressFragment = {
    __typename: 'MailingAddress';
    id: string;
    address1?: string | undefined;
    address2?: string | undefined;
    city?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    company?: string | undefined;
    country?: string | undefined;
    phone?: string | undefined;
    province?: string | undefined;
    provinceCode?: string | undefined;
    zip?: string | undefined;
    countryCode?: Types.CountryCode | undefined;
};

export const AddressFragmentDoc = gql`
    fragment Address on MailingAddress {
        __typename
        id
        address1
        address2
        city
        firstName
        lastName
        company
        country
        countryCode: countryCodeV2
        phone
        province
        provinceCode
        zip
    }
`;
