export const SANITY_BASE_DOCUMENT_QUERY = `!(_id in path("drafts.**"))`;
export const sanityTranslateDocument = `coalesce(
  *[
    ${SANITY_BASE_DOCUMENT_QUERY}
    && __i18n_base._ref == ^._id
    && __i18n_lang == $language
  ][0],
  *[
    ${SANITY_BASE_DOCUMENT_QUERY}
    && _id == ^._id
  ][0]
)`;
