interface GetUrlProps {
    domain: string;
    apiVersion: string;
}
export const getAdminUrl = ({ domain, apiVersion }: GetUrlProps) =>
    `https://${domain}/admin/api/${apiVersion}/graphql.json`;
export const getStorefrontUrl = ({ domain, apiVersion }: GetUrlProps) =>
    `https://${domain}/api/${apiVersion}/graphql.json`;

export const getStorefrontHeaders = ({
    storefrontToken,
    buyerIp,
}: {
    storefrontToken: string;
    buyerIp?: string | null;
}) => ({
    'X-Shopify-Storefront-Access-Token': storefrontToken,
    'Content-Type': 'application/json',
    ...(buyerIp ? { 'Shopify-Storefront-Buyer-IP': buyerIp } : {}),
});
export const getAdminHeaders = ({
    accessToken,
    privateToken,
    buyerIp,
}: {
    accessToken: string;
    privateToken: string;
    buyerIp?: string | null;
}) => ({
    'X-Shopify-Access-Token': accessToken,
    'X-GraphQL-Cost-Include-Fields': 'true',
    'Content-Type': 'application/json',
    'Shopify-Storefront-Private-Token': privateToken,
    'Shopify-Storefront-Buyer-IP': buyerIp ?? '',
});
