import {
    getShopifyStorefrontClientConfig,
    GetShopifyStorefrontClientConfigProps,
} from '@lib/machine-parts/storefront/utils/server';
import {
    getStorefrontClient as getShopifyStorefrontClient,
    ShopifyClientProvider,
    ShopifyStorefrontClient,
} from '@lib/shared/client-connectors/shopify';

// Shopify storefront client singleton
export const provider = ShopifyClientProvider.Apollo;

let storefrontClient: ShopifyStorefrontClient | undefined = undefined;

export function getStorefrontClient(config: GetShopifyStorefrontClientConfigProps) {
    if (!storefrontClient) {
        const storefrontConfig = getShopifyStorefrontClientConfig(config);
        storefrontClient = getShopifyStorefrontClient(provider, {
            domain: storefrontConfig.storeDomain,
            apiVersion: storefrontConfig.storefrontApiVersion,
            storefrontToken: storefrontConfig.storefrontToken,
        });
    }
    return storefrontClient;
}
