import { getApolloAdminClient, getApolloStorefrontClient } from './apollo/client';
import { getFetchAdminClient, getFetchStorefrontClient } from './fetch/client';
import {
    CreateAdminClientProps,
    CreateStorefrontClientProps,
    ShopifyAdminClient,
    ShopifyStorefrontClient,
} from './interfaces';

export enum ShopifyClientProvider {
    Apollo = 'apollo',
    Fetch = 'fetch',
}

// Storefront
const StorefrontClients: Record<
    ShopifyClientProvider,
    (props: CreateStorefrontClientProps) => ShopifyStorefrontClient
> = {
    [ShopifyClientProvider.Apollo]: getApolloStorefrontClient,
    [ShopifyClientProvider.Fetch]: getFetchStorefrontClient,
};

export const getStorefrontClient = (type: ShopifyClientProvider, props: CreateStorefrontClientProps) =>
    StorefrontClients[type](props);

// Admin
const AdminClients: Record<ShopifyClientProvider, (props: CreateAdminClientProps) => ShopifyAdminClient> = {
    [ShopifyClientProvider.Apollo]: getApolloAdminClient,
    [ShopifyClientProvider.Fetch]: getFetchAdminClient,
};

export const getAdminClient = (type: ShopifyClientProvider, props: CreateAdminClientProps) => AdminClients[type](props);
