import { createShopifyAdminClientConfig } from '@lib/machine-parts/storefront/utils/server';
import {
    getAdminClient as getShopifyAdminClient,
    ShopifyAdminClient,
    ShopifyClientProvider,
} from '@lib/shared/client-connectors/shopify';

// Shopify admin client singleton
const provider = ShopifyClientProvider.Apollo;
let adminClient: ShopifyAdminClient | undefined = undefined;

export function getAdminClient() {
    if (!adminClient) {
        adminClient = getShopifyAdminClient(provider, createShopifyAdminClientConfig());
    }
    return adminClient;
}
