import { print } from 'graphql/language/printer';
import { DocumentNode, MutationOptions, QueryOptions, TypedDocumentNode } from '@apollo/client';
import {
    CreateAdminClientProps,
    CreateStorefrontClientProps,
    ShopifyAdminClient,
    ShopifyStorefrontClient,
} from '../interfaces';
import { getAdminHeaders, getAdminUrl, getStorefrontHeaders, getStorefrontUrl } from './config';

const stringifyInput = (i: string | DocumentNode | TypedDocumentNode) => (typeof i === 'string' ? i : print(i));

export function getFetchStorefrontClient({
    domain,
    apiVersion,
    storefrontToken,
}: CreateStorefrontClientProps): ShopifyStorefrontClient {
    const getFetch = <Vars>({
        query,
        variables,
        buyerIp,
    }: {
        query: DocumentNode | TypedDocumentNode;
        variables?: Vars;
        buyerIp?: string | null;
    }) =>
        fetch(getStorefrontUrl({ domain, apiVersion }), {
            method: 'POST',
            headers: getStorefrontHeaders({ storefrontToken, buyerIp }),
            body: JSON.stringify({ query: stringifyInput(query), variables }),
        }).then((response) => response.json());

    return {
        query: <Result, Vars>(options: QueryOptions<Vars, Result>, buyerIp?: string | null) => {
            return getFetch({ query: options.query, variables: options.variables, buyerIp });
        },
        mutation: <Result, Vars>(options: MutationOptions<Result, Vars>, buyerIp?: string | null) => {
            return getFetch({ query: options.mutation, variables: options.variables, buyerIp });
        },
    };
}

export function getFetchAdminClient({
    domain,
    apiVersion,
    accessToken,
    privateToken,
}: CreateAdminClientProps): ShopifyAdminClient {
    const getFetch = <Vars>({
        buyerIp,
        query,
        variables,
    }: {
        buyerIp: string | null;
        query: DocumentNode | TypedDocumentNode;
        variables?: Vars;
    }) =>
        fetch(getAdminUrl({ domain, apiVersion }), {
            method: 'POST',
            headers: getAdminHeaders({ accessToken, privateToken, buyerIp }),
            body: JSON.stringify({ query: stringifyInput(query), variables }),
        }).then((response) => response.json());

    return {
        query: <Result, Vars>(options: QueryOptions<Vars, Result>, buyerIp: string | null) => {
            return getFetch({ buyerIp, query: options.query, variables: options.variables });
        },
        mutation: <Result, Vars>(options: MutationOptions<Result, Vars>, buyerIp: string | null) => {
            return getFetch({ buyerIp, query: options.mutation, variables: options.variables });
        },
    };
}
