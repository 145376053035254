import { fetchSanityData, SANITY_BASE_DOCUMENT_QUERY } from '@lib/machine-parts/storefront/data-access/server';
import { DEFAULT_LANGUAGE } from '@lib/machine-parts/storefront/utils';

export interface CreateOrLoginSanityData {
    title?: string;
    description?: string;
    create_button: string;
    login_button: string;
    continue_button: string;
}

const createOrLoginAccountQuery = `
coalesce(
  *[
    ${SANITY_BASE_DOCUMENT_QUERY}
    && __i18n_base._ref == $pageId 
    && __i18n_lang == $language
  ][0], 
  *[
    ${SANITY_BASE_DOCUMENT_QUERY}
    && _id == $pageId
    && __i18n_lang == $baseLanguage
  ][0]
){ 
  title,
  description,
  create_button,
  login_button,
  continue_button
}`;

export async function getCreateOrLoginSanityData(params: { language: string }) {
    const { data, ...rest } = await fetchSanityData<CreateOrLoginSanityData>({
        query: createOrLoginAccountQuery,
        params: { ...params, pageId: 'accounts.create_or_login', baseLanguage: DEFAULT_LANGUAGE },
    });

    return {
        ...rest,
        data: data ?? undefined,
    };
}
