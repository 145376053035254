import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    MutationOptions,
    OperationVariables,
    QueryOptions,
} from '@apollo/client';
import {
    CreateAdminClientProps,
    CreateStorefrontClientProps,
    ShopifyAdminClient,
    ShopifyStorefrontClient,
} from '../interfaces';
import { getAdminHeaders, getAdminUrl, getStorefrontHeaders, getStorefrontUrl } from './config';

type ClientType = 'storefront' | 'admin';
const clients: Record<ClientType, ApolloClient<unknown> | undefined> = { storefront: undefined, admin: undefined };

export interface GetClientProps {
    type: ClientType;
    uri: string;
    headers: Record<string, string>;
    useCache?: boolean;
}

export function getClient({ type, uri, headers, useCache = false }: GetClientProps) {
    let client = clients[type];
    if (!client) {
        client = new ApolloClient({
            link: createHttpLink({ uri, credentials: 'same-origin', headers }),
            cache: new InMemoryCache(),
            connectToDevTools: true,
            defaultOptions: !useCache
                ? {
                      watchQuery: {
                          fetchPolicy: 'network-only',
                          errorPolicy: 'ignore',
                      },
                      query: {
                          fetchPolicy: 'network-only',
                          errorPolicy: 'all',
                      },
                  }
                : {},
        });
        clients[type] = client;
    }
    return client;
}

const addContext = <T extends { context?: { headers?: Record<string, string> } }>(
    options: T,
    buyerIp?: string | null,
) => ({
    ...options,
    context: {
        ...(options.context ?? {}),
        headers: {
            ...(options.context?.headers ?? {}),
            ...(buyerIp ? { 'Shopify-Storefront-Buyer-IP': buyerIp } : {}),
        },
    },
});

function storefrontClientWrapper<T>(client: ApolloClient<T>) {
    return {
        query: async <Result, Vars extends OperationVariables>(
            options: QueryOptions<Vars, Result>,
            buyerIp?: string | null,
        ) => client.query<Result, Vars>(addContext(options, buyerIp)),
        mutation: <Result, Vars extends OperationVariables>(
            options: MutationOptions<Result, Vars>,
            buyerIp?: string | null,
        ) => client.mutate<Result, Vars>(addContext(options, buyerIp)),
        cache: client.cache,
    };
}

export function getApolloStorefrontClient({
    domain,
    apiVersion,
    storefrontToken,
}: CreateStorefrontClientProps): ShopifyStorefrontClient {
    return storefrontClientWrapper(
        getClient({
            type: 'storefront',
            uri: getStorefrontUrl({ domain, apiVersion }),
            headers: getStorefrontHeaders({ storefrontToken }),
        }),
    );
}

function adminClientWrapper<T>(client: ApolloClient<T>) {
    return {
        query: <Result, Vars extends OperationVariables>(options: QueryOptions<Vars, Result>, buyerIp: string | null) =>
            client.query<Result, Vars>(addContext<QueryOptions<Vars, Result>>(options, buyerIp)),
        mutation: <Result, Vars extends OperationVariables>(
            options: MutationOptions<Result, Vars>,
            buyerIp: string | null,
        ) => client.mutate<Result, Vars>(addContext<MutationOptions<Result, Vars>>(options, buyerIp)),
        cache: client.cache,
    };
}

export function getApolloAdminClient({
    domain,
    apiVersion,
    accessToken,
    privateToken,
}: CreateAdminClientProps): ShopifyAdminClient {
    return adminClientWrapper(
        getClient({
            type: 'admin',
            uri: getAdminUrl({ domain, apiVersion }),
            headers: getAdminHeaders({ accessToken, privateToken }),
        }),
    );
}
