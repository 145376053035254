export enum CustomerStatusExtension {
    Unknown = 'UNKNOWN',
    Available = 'AVAILABLE',
}

export enum CustomerMetaField {
    Admin = 'admin',
    BillingAddress = 'billing_address',
    PreviousCheckoutStep = 'previous_step',
    Vat = 'vat_number',
}
