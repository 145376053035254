import { Environment, getSanityConfig } from '@lib/machine-parts/storefront/utils';
import { createClient, SanityClient } from '@sanity/client';

import { CacheDataType, FetchSanityResult, SanityDataProps } from './interfaces';

const MAX_CACHE_SIZE = 50;
const MAX_CACHE_AGE_MS = 30_000;
const MAX_QUERY_TIMEOUT = 60_000;

export const getDatasetName = (environment?: string) =>
    environment === Environment.PROD ? 'production' : 'development';

// Cache
let cache: { key: string; data: CacheDataType; timestamp: number }[] = [];

const isExpiredTimestamp = (timestamp: number) => timestamp < Date.now() - MAX_CACHE_AGE_MS;
const formatKey = (key: string) => key.trim().replaceAll(/[\s ]+/gm, '');
function getFromCache<T>(query: string) {
    return cache.find(
        ({ key, timestamp }) => key === formatKey(query) && !isExpiredTimestamp(timestamp),
    ) as FetchSanityResult<T>;
}

function addToCache(key: string, data: CacheDataType) {
    cache.push({ key: formatKey(key), data, timestamp: Date.now() });
    cache = cache.filter(({ timestamp }) => !isExpiredTimestamp(timestamp));
    if (cache.length > MAX_CACHE_SIZE) {
        cache = cache.slice(1);
    }
}

// Client
let client: SanityClient | undefined = undefined;

// Query or Mutation call
export async function fetchSanityData<T>({
    query,
    params,
    options = {},
    log,
}: SanityDataProps & { log?: boolean }): Promise<FetchSanityResult<T>> {
    const { isEnabled = true, environment } = options;
    if (!isEnabled) return {};

    const sanityConfig = await getSanityConfig();
    if (!client) {
        client = createClient({
            ...sanityConfig,
            useCdn: true,
            dataset: environment ? getDatasetName(environment) : sanityConfig.dataset,
        });
        cache = [];
    }

    const r = getFromCache<T>(JSON.stringify({ query, params }));
    if (r) return r;

    try {
        const data = await client.fetch<T>(query, params, { timeout: MAX_QUERY_TIMEOUT });
        if (log) console.log(query, params, data);
        addToCache(JSON.stringify({ query, params }), data);
        return { data };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
        console.error(e);
        return { error: { statusCode: e.statusCode, errors: e.response?.body?.error ?? e.message } };
    }
}
