import { DEFAULT_LANGUAGE } from '@lib/machine-parts/storefront/utils';

import { SANITY_BASE_DOCUMENT_QUERY } from './constants';
import { fetchSanityData } from './getSanityQuery';
import { UseSanityDataProps } from './interfaces';

export async function getSanityData<RT>({ params, pageId, items, query: queryOverride, options }: UseSanityDataProps) {
    const query =
        (queryOverride ??
            `coalesce(
              *[
                ${SANITY_BASE_DOCUMENT_QUERY}
                && __i18n_base._ref == $pageId 
                && __i18n_lang == $language
              ][0], 
              *[
                ${SANITY_BASE_DOCUMENT_QUERY}
                && _id == $pageId
                && __i18n_lang == $baseLanguage
              ][0])`) + items;

    const { data, ...rest } = await fetchSanityData<RT>({
        query,
        params: { ...params, pageId, baseLanguage: DEFAULT_LANGUAGE },
        options,
    });

    return {
        ...rest,
        data: data ?? undefined,
    };
}
