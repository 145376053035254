import { getSanityData } from './getSanityData';
import { UseSanityDataProps } from './interfaces';

export interface FormSanityData {
    common: {
        too_long: string;
    };
    email: {
        required: string;
        invalid: string;
        in_use: string;
        placeholder?: string;
    };
    first_name: {
        required: string;
        placeholder?: string;
    };
    last_name: {
        required: string;
        placeholder?: string;
    };
    phone: {
        required: string;
        invalid: string;
        in_use: string;
        placeholder?: string;
    };
    country_code: {
        required: string;
        placeholder?: string;
    };
    password: {
        required: string;
        invalid: string;
        confirmation_not_equal: string;
        min_length: string;
        placeholder?: string;
    };
    vat: {
        placeholder: string;
        help_example: {
            zero: string;
            one: string;
        };
        required: string;
        invalid: string;
        wrong_length: string;
        conjunction: string;
        too_long: string;
        too_short: string;
        missing_letters: string;
        missing_substring: string;
        wrong_country: string;
        missing_country_code: string;
        mutation_failed: { title: string; message: string };
    };
    country: {
        required: string;
        placeholder?: string;
    };
    company: {
        required: string;
        placeholder?: string;
    };
    street: {
        required: string;
        placeholder?: string;
    };
    number: {
        required: string;
        placeholder?: string;
    };
    postalCode: {
        required: string;
        invalid: string;
        unknown: string;
        help_example: {
            zero: string;
            one: string;
        };
        placeholder?: string;
    };
    city: {
        required: string;
        placeholder?: string;
        multiple_cities: string;
    };
    provinceCode: {
        required: string;
        placeholder?: string;
    };
}

const pageId = 'settings.form';
const items = `{ 
    email, 
    first_name, 
    last_name, 
    phone, 
    country_code, 
    password, 
    vat, 
    country, 
    company, 
    street, 
    number, 
    postalCode, 
    city, 
    provinceCode,
}`;

export function getFormSanityData(params: UseSanityDataProps['params']) {
    return getSanityData<FormSanityData>({ params, pageId, items });
}
