'use client';

import * as Sentry from '@sentry/react';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import {
    CustomerInformationDocument,
    CustomerInformationQuery,
    CustomerInformationQueryVariables,
} from '@lib/machine-parts/storefront/account/data-access/server';
import { getStorefrontClient } from '@lib/machine-parts/storefront/data-access';
import { getCurrentEnv } from '@lib/machine-parts/storefront/utils';
import { useMutation } from '@tanstack/react-query';

import { CustomerLoginInput, ResetPasswordInput } from './interfaces';

export interface MutationHookInput<T = void, R = boolean> {
    onSuccess?: (success: R, args: T) => void;
    onError?: (error?: unknown) => void;
}

export interface MutationInput<T = void, R = boolean> extends MutationHookInput<T, R> {
    data: T;
}

interface AuthenticationContext {
    loggedIn: boolean;
    customerAccessToken?: string;
    customerData?: UseCustomerDataResult;
    login: (args: MutationInput<CustomerLoginInput, UseCustomerDataResult>) => Promise<UseCustomerDataResult>;
    loginByToken: (token: string) => Promise<void>;
    logout: (args: MutationHookInput) => Promise<boolean>;
    resetPassword: (args: MutationInput<ResetPasswordInput>) => Promise<boolean>;
    updateCustomerData: (args?: { accessToken?: string; firstName?: string }) => Promise<void>;
    isLoading: boolean;
}

type AuthenticationProviderProps = {
    children: ReactNode;
    customer?: UseCustomerDataResult;
    customerAccessToken?: string;
    geolocationData?: {
        ip: string;
        country_code: string;
        region: string;
        city: string;
    };
};

export const AuthenticationContext = createContext<AuthenticationContext>(undefined!);

const login_url = '/api/account/login';
const logout_url = '/api/account/logout';

export const AuthenticationProvider = ({
    children,
    customer,
    customerAccessToken,
    geolocationData,
}: AuthenticationProviderProps) => {
    const [loggedIn, setLoggedIn] = useState(!!customer?.id);
    const [accessToken, setAccessToken] = useState(customerAccessToken);
    const [customerData, setCustomerData] = useState(customer);
    const { query } = getStorefrontClient({ environment: getCurrentEnv() });

    useEffect(() => {
        if (loggedIn) {
            Sentry.setUser({
                id: customerData?.id,
                email: customerData?.email,
                ip_address: geolocationData?.ip,
                geo: geolocationData,
            });
        }
    }, [loggedIn, customerData]);

    const updateCustomerData = useCallback(
        async (args?: { accessToken?: string; firstName?: string }) => {
            const { data } = await query<CustomerInformationQuery, CustomerInformationQueryVariables>({
                query: CustomerInformationDocument,
                variables: { customerAccessToken: args?.accessToken ?? customerAccessToken! },
            });
            if (data && data.customer) {
                const isAdmin = data.customer.isAdmin?.value === 'true';
                setCustomerData({
                    id: data.customer.id ?? undefined,
                    firstName: args?.firstName ?? data.customer.firstName ?? undefined,
                    vatNumber: data.customer.vatNumber?.value,
                    email: data.customer.email,
                    isAdmin,
                });
            }
        },
        [customerAccessToken, query],
    );

    const loginByToken = useCallback(
        async (accessToken: string) => {
            setLoggedIn(true);
            setAccessToken(accessToken);
            await updateCustomerData({ accessToken });
        },
        [updateCustomerData],
    );

    const { mutateAsync: loginMutateAsync, isLoading: isLoginLoading } = useMutation(
        ['login'],
        async (args: CustomerLoginInput): Promise<UseCustomerDataResult> => {
            const response = await fetch(login_url, {
                method: 'POST',
                body: JSON.stringify(args),
            });
            if (response.status === 404) throw new Error('User not found');
            if (response.status === 401) {
                const body = await response.json();
                throw new Error(body.accessCustomerErrors?.at(0));
            }
            if (!response.ok) throw new Error('Could not login');
            const { accessToken, customer } = await response.json();
            await loginByToken(accessToken);
            return customer;
        },
    );
    const login: AuthenticationContext['login'] = useCallback(
        ({
            data,
            onSuccess,
            onError,
        }: {
            data: {
                email: string;
                password: string;
            };
            onSuccess?: (customer: UseCustomerDataResult, args: CustomerLoginInput) => void;
            onError?: (error: any) => void;
        }) => {
            return loginMutateAsync(data, {
                onSuccess,
                onError,
            });
        },
        [loginMutateAsync],
    );

    const { mutateAsync: logoutMutateAsync, isLoading: isLogoutLoading } = useMutation(
        ['logout'],
        async (): Promise<boolean> => {
            const response = await fetch(logout_url, { method: 'POST' });
            if (!response.ok) throw new Error('Could not logout');
            setLoggedIn(false);
            setAccessToken(undefined);
            setCustomerData(undefined);
            return true;
        },
    );
    const logout: AuthenticationContext['logout'] = useCallback(
        ({ onSuccess, onError }: MutationHookInput) => {
            return logoutMutateAsync(undefined, { onSuccess, onError });
        },
        [logoutMutateAsync],
    );

    const { mutateAsync: resetPasswordMutateAsync, isLoading: isResetLoading } = useMutation(
        ['reset-password'],
        async (args: ResetPasswordInput): Promise<boolean> => {
            const response = await fetch('/api/account/reset-password', {
                method: 'POST',
                body: JSON.stringify(args),
            });
            if (response.status === 404) return false;
            if (!response.ok) throw new Error('Failed to set new password');
            const { accessToken } = await response.json();
            await loginByToken(accessToken);
            return true;
        },
    );

    const resetPassword: AuthenticationContext['resetPassword'] = useCallback(
        ({
            data,
            onSuccess,
            onError,
        }: {
            data: ResetPasswordInput;
            onSuccess?: (success: boolean, args: ResetPasswordInput) => void;
            onError?: (error: unknown) => void;
        }) => {
            return resetPasswordMutateAsync(data, { onSuccess, onError });
        },
        [resetPasswordMutateAsync],
    );

    return (
        <AuthenticationContext.Provider
            value={{
                customerAccessToken: accessToken,
                loggedIn,
                login,
                loginByToken,
                logout,
                resetPassword,
                updateCustomerData,
                customerData,
                isLoading: isLoginLoading || isLogoutLoading || isResetLoading,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAuthenticationContext = () => useContext(AuthenticationContext);
