import { getSanityData, UseSanityDataProps } from '@lib/machine-parts/storefront/data-access/server';
import { ModalStatus } from '@lib/machine-parts/storefront/utils';

export interface AccountSettingsSanityData {
    settings_section: {
        title: string;
        description?: string;
        information: {
            title: string;
            description?: string;
            edit_button: string;
            email: string;
            firstName: string;
            lastName: string;
            phone: string;
            vat: string;
            vat_missing: string;
            fields_missing: {
                title: string;
                description: string;
            };
            modal: {
                title: string;
                description?: string;
                button: Record<ModalStatus, string>;
                text: { loading: string; failed: string; success: string };
                vat_mutation_failed: { title: string; message: string };
            };
        };
        address: {
            shipping: {
                section_title?: string;
                title: string;
                description?: string;
                addressLines?: string[];
                error?: string;
                edit_button: string;
                add_button: string;
                fields_missing: {
                    title: string;
                    description: string;
                };
            };
            billing: {
                section_title?: string;
                title: string;
                description?: string;
                addressLines?: string[];
                error?: string;
                edit_button: string;
                add_button: string;
                fields_missing: {
                    title: string;
                    description: string;
                };
            };
            country: string;
            company: string;
            address: string;
            postalCode: string;
            city: string;
            phone: string;
        };
    };
}

const pageId = 'accounts.settings';
const items = '{ settings_section }';

export async function getAccountSettingsSanityData(params: UseSanityDataProps['params']) {
    return getSanityData<AccountSettingsSanityData>({ params, pageId, items });
}
