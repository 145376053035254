interface GetUrlProps {
    domain: string;
    apiVersion: string;
}
export const getAdminUrl = ({ domain, apiVersion }: GetUrlProps) => `${domain}/admin/api/${apiVersion}/graphql.json`;
export const getStorefrontUrl = ({ domain, apiVersion }: GetUrlProps) => `${domain}/api/${apiVersion}/graphql.json`;

export const getStorefrontHeaders = ({ storefrontToken }: { storefrontToken: string }) => ({
    'X-Shopify-Storefront-Access-Token': storefrontToken,
});
export const getAdminHeaders = ({ accessToken, privateToken }: { accessToken: string; privateToken: string }) => ({
    'X-Shopify-Access-Token': accessToken,
    'X-GraphQL-Cost-Include-Fields': 'true',
    'Content-Type': 'application/json',
    'Shopify-Storefront-Private-Token': privateToken,
});
