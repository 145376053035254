'use client';

import { PropsWithChildren } from 'react';

import { vatCountryCodeOptions } from '@lib/machine-parts/storefront/account/utils';
import { Input, ListSelect } from '@lib/machine-parts/storefront/ui';
import { ShippableCountry } from '@lib/machine-parts/storefront/utils';

interface InputProps<Type> {
    value: Type;
    onChange: (value: Type) => void;
}

export interface VatInputProps {
    countryCode: InputProps<ShippableCountry>;
    vat: InputProps<string> & { placeholder?: string; error?: string | boolean; valid?: boolean };
    loading?: boolean;
    position?: 'north' | 'south';
    onBlur?: () => void;
}

export function VatInput({
    countryCode,
    vat: { onChange: vatOnChange, ...vat },
    loading,
    children,
    position = 'south',
    onBlur,
}: PropsWithChildren<VatInputProps>) {
    return (
        <div className="w-full flex flex-col gap-2">
            <div className="flex gap-4">
                <ListSelect<ShippableCountry>
                    id="country-code-dropdown"
                    options={vatCountryCodeOptions}
                    selected={countryCode.value}
                    handleChange={countryCode.onChange}
                    variant="register"
                    position={position}
                />
                <Input
                    {...vat}
                    data-testid="vatNumber"
                    altBorder
                    handleChange={vatOnChange ? (event) => vatOnChange(event.target.value) : undefined}
                    loading={loading}
                    onBlur={onBlur}
                />
            </div>

            {children && <span className="text-xs">{children}</span>}
        </div>
    );
}
