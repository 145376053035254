export {
    CustomerInformationDocument,
    type CustomerInformationQuery,
    type CustomerInformationQueryVariables,
} from './CustomerInformationQuery.generated';
export {
    CustomerRichInformationDocument,
    type CustomerRichInformationQuery,
    type CustomerRichInformationQueryVariables,
} from './CustomerRichInformationQuery.generated';
