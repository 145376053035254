import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import { AddressFragmentDoc } from '../fragments/addressFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerInformationQueryVariables = Types.Exact<{
    customerAccessToken: Types.Scalars['String'];
}>;

export type CustomerInformationQuery = {
    __typename?: 'QueryRoot';
    customer?:
        | {
              __typename?: 'Customer';
              id: string;
              email?: string | undefined;
              firstName?: string | undefined;
              lastName?: string | undefined;
              phone?: string | undefined;
              vatNumber?: { __typename?: 'Metafield'; value: string } | undefined;
              isAdmin?: { __typename?: 'Metafield'; type: string; value: string } | undefined;
              defaultAddress?: { __typename?: 'MailingAddress'; id: string } | undefined;
              addresses: {
                  __typename?: 'MailingAddressConnection';
                  nodes: Array<{
                      __typename: 'MailingAddress';
                      id: string;
                      address1?: string | undefined;
                      address2?: string | undefined;
                      city?: string | undefined;
                      firstName?: string | undefined;
                      lastName?: string | undefined;
                      company?: string | undefined;
                      country?: string | undefined;
                      phone?: string | undefined;
                      province?: string | undefined;
                      provinceCode?: string | undefined;
                      zip?: string | undefined;
                      countryCode?: Types.CountryCode | undefined;
                  }>;
              };
              billingAddresses?: { __typename?: 'Metafield'; value: string } | undefined;
          }
        | undefined;
};

export const CustomerInformationDocument = gql`
    query CustomerInformation($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
            id
            email
            firstName
            lastName
            phone
            vatNumber: metafield(key: "vat_number", namespace: "custom") {
                value
            }
            isAdmin: metafield(key: "admin", namespace: "custom") {
                type
                value
            }
            defaultAddress {
                id
            }
            addresses(first: 6) {
                nodes {
                    ...Address
                }
            }
            billingAddresses: metafield(key: "billing_address", namespace: "custom") {
                value
            }
        }
    }
    ${AddressFragmentDoc}
`;

/**
 * __useCustomerInformationQuery__
 *
 * To run a query within a React component, call `useCustomerInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInformationQuery({
 *   variables: {
 *      customerAccessToken: // value for 'customerAccessToken'
 *   },
 * });
 */
export function useCustomerInformationQuery(
    baseOptions: Apollo.QueryHookOptions<CustomerInformationQuery, CustomerInformationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CustomerInformationQuery, CustomerInformationQueryVariables>(
        CustomerInformationDocument,
        options,
    );
}
export function useCustomerInformationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CustomerInformationQuery, CustomerInformationQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CustomerInformationQuery, CustomerInformationQueryVariables>(
        CustomerInformationDocument,
        options,
    );
}
export type CustomerInformationQueryHookResult = ReturnType<typeof useCustomerInformationQuery>;
export type CustomerInformationLazyQueryHookResult = ReturnType<typeof useCustomerInformationLazyQuery>;
export type CustomerInformationQueryResult = Apollo.QueryResult<
    CustomerInformationQuery,
    CustomerInformationQueryVariables
>;
