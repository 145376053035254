'use client';

import { useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';

import { VatInput as VatInputUi } from '@lib/machine-parts/storefront/account/ui';
import { DefaultVatForm } from '@lib/machine-parts/storefront/account/utils';
import { FormSanityData } from '@lib/machine-parts/storefront/data-access';
import { getFormError, isFormValid } from '@lib/machine-parts/storefront/ui';
import {
    formatTranslation,
    SHIPPABLE_COUNTRY_NAMES,
    ShippableCountry,
    VAT_EXAMPLE,
} from '@lib/machine-parts/storefront/utils';

export type VatFormProps<Form extends FieldValues = DefaultVatForm> = {
    countryCodeEntry: Path<Form>;
    vatNumberEntry: Path<Form>;
    translation?: FormSanityData['vat'];
    isUpdate?: boolean;
    loading?: boolean;
    position?: 'south' | 'north';
    onBlur?: () => void;
};

export function VatInput<Form extends FieldValues = DefaultVatForm>({
    countryCodeEntry,
    vatNumberEntry,
    translation,
    isUpdate = false,
    loading,
    position = 'south',
    onBlur,
}: VatFormProps<Form>) {
    const t = formatTranslation<FormSanityData['vat']>(translation);
    const { setValue, trigger, watch, formState } = useFormContext<Form>();
    const countryCode = watch(countryCodeEntry) as ShippableCountry;
    const vatNumber = watch(vatNumberEntry);

    const translationContext = useMemo(
        () => ({
            countryCode,
            countryName: countryCode ? SHIPPABLE_COUNTRY_NAMES[countryCode] : undefined,
            vatExample: countryCode && VAT_EXAMPLE[countryCode] ? VAT_EXAMPLE[countryCode] : 'none',
        }),
        [countryCode],
    );

    return (
        <VatInputUi
            countryCode={{
                value: countryCode,
                onChange: (value: ShippableCountry) => {
                    setValue(countryCodeEntry, value as PathValue<Form, Path<Form>>, { shouldDirty: true });
                    trigger(vatNumberEntry);
                },
            }}
            vat={{
                value: vatNumber,
                onChange: (value) => {
                    setValue(vatNumberEntry, value as PathValue<Form, Path<Form>>, { shouldDirty: true });
                    trigger(vatNumberEntry);
                },
                placeholder: t('placeholder', { context: translationContext }),
                error: getFormError<Form>({
                    entryPoint: vatNumberEntry,
                    formState,
                    validateUntouched: !isUpdate && formState.submitCount > 0,
                }),
                valid: isFormValid<Form>({ entryPoint: vatNumberEntry, formState, validateUntouched: isUpdate }),
            }}
            loading={loading}
            position={position}
            onBlur={onBlur}
        >
            {t(countryCode ? 'help_example.one' : 'help_example.zero', {
                context: translationContext,
                allowUndefined: true,
            })}
        </VatInputUi>
    );
}
